<template>
  <div class="error-page text-center my-10" style="width: 100vw; height: 100vh; background-image:url('@/assets/images/auth/2aw4x8sj.bmp')">
    <h1 class="font-30 black--text font-500 d-block my-4">Oops!</h1>
    <p class="text-center font-15 lightGreyText--text"  >{{$t('cantFindPage')}}</p>
<div class="d-flex align-items-center flex-column w-100" style="justify-content: center; align-items: center; ">
  <img src="@/assets/images/auth/404-error.png" width="300px"  alt="">
    <v-btn @click="$router.replace('/')" color="primary"
      ><span class="font-20 font-500">{{$t('goToHomepage')}}</span></v-btn
    >
</div>    

  </div>
</template>

<script>
export default {};
</script>

<style></style>
